import { Typography, Grid, Paper, Button } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { StyledTableCell, StyledTableRow, IColumn } from "./shared";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from "react";
import Loading from "../Layout/Loading";
import LoadingError from "../Layout/LoadingError";
import { LIST_USERS } from "./admin.user.queries";
import { useQuery } from "@apollo/client";
import { FormattedDate } from "react-intl";
import { ViewAgenda } from "@mui/icons-material";

/**
 *
 */
export default function AdminUsersComponent() {
  const [ page, setPage ] = useState(0);

  const { loading, error, data, fetchMore } = useQuery(LIST_USERS, { variables: { page } });

  // TODO: fetchMore vs refetch (use next page, not loadMore).

  if (loading) return <Loading />
  if (error) return <LoadingError />

  const columns: IColumn[] = [
    {
      header: 'Name',
      getValue: (user: any) => user.givenName ?? ''
    },
    {
      header: 'Family Name',
      getValue: (user: any) => user.family ?? ''
    },
    {
      header: 'Email',
      getValue: (user: any) => user.email ?? ''
    },
    {
      header: 'ID',
      getValue: (user: any) => user._id ?? ''
    },
  ];

  return (
    <Grid container rowSpacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6}>
        <Typography component="h5" variant="h5" paragraph>Users</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {
                  columns.map((column: any, index: number) => {
                    return (<StyledTableCell key={index}>{column.header}</StyledTableCell>)
                  })
                }
                <StyledTableCell>Created At</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.users?.users?.map((user: any, index: number) => {
              return (
                <StyledTableRow key={index}>
                  {
                    columns.map((column: any, i: number) => {
                      return (<StyledTableCell key={i}>{column.getValue(user)}</StyledTableCell>)
                    })
                  }
                  <StyledTableCell>
                    <FormattedDate value={user.createdAt} />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Button><ViewAgenda /></Button>
                  </StyledTableCell>
                </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {
          data?.users?.hasMore ? (<Button variant="contained">Load More</Button>): ''
        }
      </Grid>
    </Grid>
  );
}
