import { gql } from "@apollo/client";

export const VENDOR_ORDER_DONATION_FIELDS = gql`
  fragment VendorOrderDonationFields on VendorOrderDonation {
    _id
    donationId
    orderedItems {
      _id
      itemId
      item {
        ...ItemFields
      }
    }
  }
`;

export const GET_VENDOR_ORDER_DONATION = gql`
  query GetVendorOrderDonation($id: ID!) {
    getVendorOrderDonation(id: $id) {
      ...VendorOrderDonationFields

      donation {
        _id
        user {
          _id
          givenName
          familyName
          email
        }
      }
    }
  }
`;

export const CREATE_VENDOR_ORDER_DONATION = gql`
  mutation CreateVendorOrderDonation($input: CreateVendorOrderDonationInput!) {
    createVendorOrderDonation(input: $input) {
      ...VendorOrderDonationFields
    }
  }
`;

// TODO: update??
