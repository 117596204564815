import { Typography, Box, Grid, Stack, } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import AdminDonationItemTableForm from "./AdminDonationItemTableForm";
import CreateVendorOrderFormComponent from "../../VendorOrder/CreateVendorOrderFormComponent";

/**
 *
 */
export default function AdminAddToVendorOrderForm() {
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography component="h6" variant="h6" paragraph>Start Vendor Order</Typography>
        {/* // TODO: Select from latest */}
        {/* // TODO: - OR - Create new. */}
        {/* <CreateVendorOrderFormComponent donationItems={pendingItems} /> */}
      </Grid>
    </Grid>
  );
}
