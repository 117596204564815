import { Typography, Grid } from "@mui/material";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SettingsIcon from '@mui/icons-material/Settings';
import { Flag } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { USER_SUMMARY } from "../Users/user.queries";
import { useAuth0 } from "@auth0/auth0-react";
import DashboardLink from "./DashboardLink";
import UserLatestDonation from "./UserLatestDonation";
import DashboardOrgsList from "./DashboardOrgsList";

/**
 *
 * The User Dashboard Page.
 */
export default function DashboardPage() {
  const { user } = useAuth0();

  const { data } = useQuery(USER_SUMMARY);

  const donation = data?.userSummary?.donation ?? null;

  const welcomeText = getWelcomeText();

  function getWelcomeText(): string {
    if (user && user.given_name) {
      return `Hi, ${user.given_name}!`;
    }

    return 'Hi!';
  }

  return (
    <Grid container rowSpacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6}>
        <Typography component="h5" variant="h5" paragraph>{welcomeText}</Typography>
        <Typography component="p" variant="body1" paragraph>This is your personal dashboard where you can view donations and campaigns you've created.</Typography>
        <UserLatestDonation donation={donation} />
      </Grid>
      <Grid item xs={12} md={6} sx={{ mb: 3 }}>
        <DashboardOrgsList organizations={data?.userSummary?.organizations ?? []} />
      </Grid>

      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <DashboardLink
          title="My Donations"
          description="See all the donations you've made on Donatic."
          icon={(<VolunteerActivismIcon />)}
          route="/dashboard/donations"
        />

        <DashboardLink
          title="My Campaigns"
          description="Create, edit and manage your Donatic campaigns."
          icon={(<Flag />)}
          route="/dashboard/campaigns"
        />

        <DashboardLink
          title="My Addresses"
          description="View, create, edit and delete your addresses."
          icon={(<ImportContactsIcon />)}
          route="/dashboard/addresses"
        />

        <DashboardLink
          title="Account Settings"
          description="View, edit and manage your Donatic account."
          icon={(<SettingsIcon />)}
          route="/dashboard/account"
        />
      </Grid>
    </Grid>
  );
}
