import { IDonation } from "../Donations/donation.model";

export default function DonorName({ donation }: { donation: IDonation }) {
  console.log('donorname', donation, 'donation.user', donation.user)
  if (!donation.user?.givenName || !donation.user?.familyName) {
    return (<span>{donation.user?.email}</span>);
  }
  
  return (<span>{[donation.user?.givenName, donation.user?.familyName].filter(value => !!value).join(' ')}</span>);
}
