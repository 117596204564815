import { Typography, Box, Grid, Stack, } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import AdminDonationItemTableForm from "./AdminDonationItemTableForm";
import CreateVendorOrderFormComponent from "../../VendorOrder/CreateVendorOrderFormComponent";
import AdminAddToVendorOrderForm from "./AdminAddToVendorOrderForm";

/**
 *
 */
export default function AdminDonationVendorOrders({ donation }: { donation: any }) {
  const { orderedItems, pendingItems } = donation?.items?.reduce((acc: any, donationItem: any) => {
    const { quantity, itemId } = donationItem;

    const vendorOrderDonations = donation.vendorOrderDonations?.filter((vo: any) => vo.orderedItems.find((oi: any) => oi.item.itemId === donationItem.itemId));

    const quantityOrdered = vendorOrderDonations.reduce((acc: any, vo: any) => {
      const orderedItem = vo.orderedItems.find((oi: any) => {
        return oi.item.itemId === itemId;
      });

      if (orderedItem) {
        return acc + orderedItem.quantity;
      }

      return acc;
    }, 0);

    const orderedItems = [...acc.orderedItems];
    const pendingItems = [...acc.pendingItems];

    if (quantityOrdered === quantity || quantityOrdered > 0) {
      orderedItems.push(donationItem);
    }

    if (quantityOrdered < quantity || quantityOrdered < 1) {
      pendingItems.push(donationItem);
    }

    return {
      orderedItems,
      pendingItems,
    }
  }, { orderedItems: [], pendingItems: []});

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Stack>
          {donation?.vendorOrders?.map((item: any, index: number) => {
            return (
            <Box key={index}>
              <Typography component="h6" variant="h6" paragraph>Vendor Order: {item.vendorOrderId}</Typography>
            </Box>
            )
          })}
        </Stack>
      </Grid>

      {
        donation?.vendorOrders?.length < 1 && (
          <Grid item xs={12}>
            <Typography component="p" paragraph>No vendor orders found.</Typography>
          </Grid>
        )
      }

      {
        pendingItems.length > 0 ? (
          <Grid item xs={12}>
            <Typography component="h6" variant="h6" paragraph>Pending Items</Typography>
            <AdminAddToVendorOrderForm />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography component="p" paragraph>All items ordered!</Typography>
          </Grid>
        )
      }

      {/* <Grid item xs={12}>
        <Typography component="h6" variant="h6" paragraph>Pending Items</Typography>
          <CreateVendorOrderFormComponent donationItems={pendingItems} />
      </Grid> */}
    </Grid>
  );
}
