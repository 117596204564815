import { useQuery } from "@apollo/client";
import { Modal, Box, Typography, Grid, Button, Paper } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { StyledTableCell, StyledTableRow, IColumn, IColumnWithElement } from "../shared";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LIST_DONATIONS } from "./admin.donation.queries";
import Loading from "../../Layout/Loading";
import LoadingError from "../../Layout/LoadingError";
import { Replay, ViewAgenda } from "@mui/icons-material";
import { FormattedDate } from "react-intl";
import AdminDonationModal from "./AdminDonationModal";
import Price from "../../shared/Price";

export const adminDonationModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflow: 'scroll'
};


/**
 *
 */
export default function AdminDonationsComponent() {
  const navigate = useNavigate();
  const [ page, setPage ] = useState(0);
  // TODO - filter by status?
  const { loading, error, data, fetchMore, refetch } = useQuery(LIST_DONATIONS, { variables: { page } });

  // TODO: fetchMore vs refetch (use next page, not loadMore).

  const [ donation, setDonation ] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (donation: any) => {
    setDonation(donation);
    setOpenModal(true);
  }
  const handleCloseModal = () => setOpenModal(false);

  if (loading) return <Loading />
  if (error) return <LoadingError />

  const columns: IColumnWithElement[] = [
    {
      header: 'Donor',
      getValue: (donation: any) => donation.user?.givenName ?? ''
    },
    {
      header: 'Fund',
      getValue: (donation: any) => donation.fund?.name ?? ''
    },
    {
      header: 'Organization',
      getValue: (donation: any) => donation.fund?.organization?.name ?? ''
    },
    {
      header: 'Service Fee',
      getValue: (donation: any) =>  {
        return (<Price value={donation.serviceFee} overrideCurrency={donation.currency} />)
      }
    },
    {
      header: 'Total',
      getValue: (donation: any) => {
        return (<Price value={donation.total} overrideCurrency={donation.currency} />)
      }
    },
    {
      header: 'Status',
      getValue: (donation: any) => donation.status ?? ''
    },
    {
      header: 'Delivery Status',
      getValue: (donation: any) => donation.deliveryStatus ?? ''
    }
  ]

  function refresh() {
    return refetch();
  }

  return (
    <Grid container rowSpacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6}>
        <Typography component="h5" variant="h5" paragraph>Donations <Replay onClick={() => refresh()} /></Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {
                  columns.map((column: any, index: number) => {
                    return (<StyledTableCell key={index}>{column.header}</StyledTableCell>)
                  })
                }
                <StyledTableCell>Created At</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.donations?.donations?.map((donation: any, index: number) => {
              return (
                <StyledTableRow key={index}>
                  {
                    columns.map((column: any, i: number) => {
                      return (<StyledTableCell key={i}>{column.getValue(donation)}</StyledTableCell>)
                    })
                  }
                  <StyledTableCell>
                    <FormattedDate value={donation.createdAt} />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Button onClick={() => handleOpenModal(donation)}><ViewAgenda /></Button>
                  </StyledTableCell>
                </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {
          data?.donations?.hasMore ? (<Button variant="contained">Load More</Button>): ''
        }
      </Grid>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={adminDonationModalStyle}>
          <AdminDonationModal donation={donation} closeModal={handleCloseModal} />
        </Box>
      </Modal>
    </Grid>
  );
}
