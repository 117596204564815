import { Grid, Stack, Typography } from "@mui/material";
import { DonationItemModel } from "../../Donations/donation-item.model";
import AdminDonationItem from "../../Donations/AdminDonationItem";
import "react-datepicker/dist/react-datepicker.css";
import AdminDonationItemTableOrdered from "./AdminDonationItemTableOrdered";
import AdminDonationItemTablePending from "./AdminDonationItemTablePending";

/**
 *
 */
export default function AdminDonationItems({ donation }: { donation: any }) {
  const { orderedItems, pendingItems } = donation?.items?.reduce((acc: any, donationItem: any) => {
    const { quantity, itemId } = donationItem;

    const vendorOrderDonations = donation.vendorOrderDonations?.filter((vo: any) => vo.orderedItems.find((oi: any) => oi.item.itemId === donationItem.itemId));

    const quantityOrdered = vendorOrderDonations.reduce((acc: any, vo: any) => {
      const orderedItem = vo.orderedItems.find((oi: any) => {
        return oi.item.itemId === itemId;
      });

      if (orderedItem) {
        return acc + orderedItem.quantity;
      }

      return acc;
    }, 0);

    const orderedItems = [...acc.orderedItems];
    const pendingItems = [...acc.pendingItems];

    if (quantityOrdered === quantity || quantityOrdered > 0) {
      orderedItems.push(donationItem);
    }

    if (quantityOrdered < quantity || quantityOrdered < 1) {
      pendingItems.push(donationItem);
    }

    return {
      orderedItems,
      pendingItems,
    }
  }, { orderedItems: [], pendingItems: []});

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography component="h6" variant="h6" paragraph>Ordered Items</Typography>
        <AdminDonationItemTableOrdered donationItems={orderedItems} vendorOrders={donation.vendorOrders} />
      </Grid>
      <Grid item xs={12}>
        <Typography component="h6" variant="h6" paragraph>Pending Items</Typography>
        <AdminDonationItemTablePending donationItems={pendingItems} vendorOrders={donation.vendorOrders} />
      </Grid>
      <Grid item xs={12}>
        <Typography component="h6" variant="h6" paragraph>All Donation Items</Typography>
        <Stack>
          {donation?.items?.map((item: DonationItemModel, index: number) => {
            const vendorOrders = donation.vendorOrders?.filter((vo: any) => vo.orderedItems.find((oi: any) => oi.item.itemId === item.itemId));
            
            return <AdminDonationItem key={index} donationItem={item} vendorOrders={vendorOrders} />;
          })}
        </Stack>
      </Grid>
    </Grid>
  );
}
