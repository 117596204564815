/**
 *
 * Floating cart button
 */
import { SpeedDial } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ShoppingCart } from "@mui/icons-material";
import { StyledCartBadge } from "./StyledCartBadge";

export default function FloatingCartButton({ count }: { count: number }) {
  const navigate = useNavigate();

  function goToCart() {
    navigate('/cart');
  }

  return (
    <SpeedDial ariaLabel="Basket" sx={{ position: 'fixed', right: '3rem' }} color="primary" onClick={goToCart} icon={(
      <StyledCartBadge color="secondary" overlap="rectangular" badgeContent={count}>
        <ShoppingCart />
      </StyledCartBadge>
    )
    }></SpeedDial>
  );
}
