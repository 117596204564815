import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { StyledTableCell, StyledTableRow, IColumn } from "../shared";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from '@mui/material';
import Price from '../../shared/Price';

// TODO.... should this just be displayed in VendorOrders?

// TODO - should we iterate over VendorOrders first??? and group by items... in case multiple VOS for the same item

const columns: IColumn[] = [
  {
    header: 'Image',
    getValue: (donationItem: any) => {
      return donationItem.item.imageUrl ? (
        <img src={donationItem.item.imageUrl} alt={donationItem.item.name} style={{ width: '50px', height: '50px' }} />
      ) : (
        <img src="https://via.placeholder.com/50" alt={donationItem.item.name} style={{ width: '50px', height: '50px' }} />
      )
    }
  },
  {
    header: 'Name',
    getValue: (donationItem: any) => donationItem.item?.name ?? ''
  },
  {
    header: 'Quantity',
    getValue: (donationItem: any) => donationItem?.quantity ?? ''
  },
  {
    header: 'Donatic Price',
    getValue: (donationItem: any) => {
      return (<Price price={donationItem.item.price} />)
    }
  },
  {
    header: 'Vendor',
    getValue: (donationItem: any) => {
      return (<Price value={donationItem?.total} />)
    }
  },
  {
    header: 'Vendor Price',
    getValue: (orderedItem: any) => {
      return (<Price value={orderedItem.vendorValue} overrideCurrency={orderedItem.vendorCurrency} />)
    }
  },
  {
    header: 'Vendor Order #',
    getValue: (vendorOrder: any) => vendorOrder.vendorOrderId
  },
];

export default function AdminDonationItemTableOrdered({ donationItems }: { donationItems: any[], vendorOrders: any[] }) {
  return (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          {
            columns.map((column: any, index: number) => {
              return (<StyledTableCell key={index}>{column.header}</StyledTableCell>)
            })
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {donationItems?.map((donation: any, index: number) => {
          // TODO - get vendorOrders...
        return (
          <StyledTableRow key={index}>
            {
              columns.map((column: any, i: number) => {
                return (<StyledTableCell key={i}>{column.getValue(donation)}</StyledTableCell>)
              })
            }
          </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
  );
}