import { Typography, Grid, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import AdminCampaignsComponent from "./AdminCampaignsComponent";
import AdminDonationsComponent from "./AdminDonation/AdminDonationsComponent";
import AdminUsersComponent from "./AdminUsersComponent";
import AdminOrganizationsComponent from "./AdminOrganizationsComponent";
import AdminItemsComponent from "./AdminItemsComponent";
import { Flag, Inventory, People, ShoppingBasket } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { USER_SUMMARY } from "../Users/user.queries";
import { useQuery } from "@apollo/client";
import Loading from "../Layout/Loading";
import LoadingError from "../Layout/LoadingError";

interface TabEl {
  title: string;
  icon: JSX.Element,
  component: JSX.Element
}

/**
 *
 * The Admin Dashboard Page.
 */
export default function AdminDashPage() {
  const { data, loading, error } = useQuery(USER_SUMMARY);

  const [ tab, setTab ] = useState(0);
  function onSwitchTab(_: React.SyntheticEvent, newValue: number) {
    setTab(newValue);
  }

  const tabs: TabEl[] = [
    {
      title: 'Donations',
      icon: (<ShoppingBasket />),
      component: (<AdminDonationsComponent />),
    },
    {
      title: 'Campaigns',
      icon: (<Flag />),
      component: (<AdminCampaignsComponent />),
    },
    {
      title: 'Users',
      icon: (<People />),
      component: (<AdminUsersComponent />),
    },
    {
      title: 'Organizations',
      icon: (<div></div>),
      component: (<AdminOrganizationsComponent />),
    },
    {
      title: 'Items',
      icon: (<Inventory />),
      component: (<AdminItemsComponent />),
    },
  ];

  if (loading) return <Loading />
  if (error) return <LoadingError />
  if (data && !data.userSummary.isAdmin) {
    return (<LoadingError />)
  }

  return (
    <Grid container rowSpacing={2} sx={{ mt: 1, pl: 3, pr: 3 }}>
      <Grid item xs={12}>
        <Tabs value={tab} onChange={onSwitchTab}>
          {
            tabs.map((tabEl, index) => {
              return (<Tab label={tabEl.title} id={tabEl.title} icon={tabEl.icon} iconPosition="start" key={index} />)
            })
          }
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {
          tabs[tab].component
        }
      </Grid>
    </Grid>
  );
}
