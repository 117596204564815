import { Typography, Grid, Tabs, Tab } from "@mui/material";
import { Info, Inventory, ShoppingBasket } from "@mui/icons-material";
import { useState } from "react";
import AdminDonationInfo from "./AdminDonationInfo";
import { useQuery } from "@apollo/client";
import { ADMIN_DONATION_DETAIL } from "./admin.donation.queries";
import Loading from "../../Layout/Loading";
import LoadingError from "../../Layout/LoadingError";
import AdminDonationItems from "./AdminDonationItems";
import AdminDonationVendorOrders from "./AdminDonationVendorOrders";

interface TabEl {
  title: string;
  icon: JSX.Element,
  component: JSX.Element
}

/**
 *
 */
export default function AdminDonationModal({ donation }: { donation: any, closeModal: any }) {
  const [ tab, setTab ] = useState(0);
  function onSwitchTab(_: React.SyntheticEvent, newValue: number) {
    setTab(newValue);
  }

  const { data, loading, error } = useQuery(ADMIN_DONATION_DETAIL, { variables: { donationId: donation._id } });

  if (loading) return <Loading />
  if (error) return <LoadingError />

  const tabs: TabEl[] = [
    {
      title: 'Details',
      icon: (<Info />),
      component: (<AdminDonationInfo donation={data.donation} />),
    },
    {
      title: 'Donation Items',
      icon: (<Inventory />),
      component: (<AdminDonationItems donation={data.donation} />),
    },
    {
      title: 'Vendor Orders',
      icon: (<ShoppingBasket />),
      component: (<AdminDonationVendorOrders donation={data.donation} />),
    },
  ];

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5" paragraph>Donation Detail: {donation.shortCode}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Tabs value={tab} onChange={onSwitchTab}>
          {
            tabs.map((tabEl, index) => {
              return (<Tab label={tabEl.title} id={tabEl.title} icon={tabEl.icon} iconPosition="start" key={index} />)
            })
          }
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        {
          tabs[tab].component
        }
      </Grid>
    </Grid>
  );
}
